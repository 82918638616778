<template>
  <div class="banner-body">
    <div class="banner-content">
      <!-- 图片展示 -->
      <div class="header">
        <el-image
          style="width: 100%; height: 100%"
          :src="bannerImg"
          fit="cover"
        ></el-image>
      </div>

      <!-- 四个阶段 -->
      <div class="four-stage">
        <div class="four-stage-box">
          <router-link
            :to="{
              path: item.route,
              query: {
                titleId: titleId,
                nodeId: item.titleId,
              },
            }"
            class="fs-title"
            v-for="(item, index) in fourStageChildTitle"
            :key="index"
            :class="{ bg: $route.path.indexOf(item.route) !== -1 }"
          >
            <div class="fs-title-chinese">{{ item.name }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
import { mapActions } from "vuex";
export default {
  name: "banner",
  props: {
    titleId: {
      type: String,
    },
  },
  data() {
    return {
      fourStageTitle: [],
      fourStageChildTitle: [],
      nodeId: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["setNodeId"]),
    async init() {
      await this.queryTitleByLanguage();
      await this.queryTitleByLanguageAndTitleId();
      this.initNodeId();
    },
    initNodeId() {
      const first = this.fourStageChildTitle[0];
      if (!first) {
        return;
      }
      this.nodeId = first.titleId;
      this.setNodeId({
        titleId: this.titleId,
        nodeId: this.nodeId,
      });
    },
    queryTitleByLanguage() {
      let lang = localStorage.getItem("lang") || "CN";
      return axios
        .get("https://www.claybbt.com/queryTitleByLanguage", {
          params: {
            language: lang,
            titleType: 1,
          },
        })
        .then((res) => {
          console.log(res,'res1')
          if (res.data.code == 0) {
            this.fourStageTitle = res.data.data;
          } else {
            this.$message.error(data.msg);
          }
        });
    },

    queryTitleByLanguageAndTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      return axios
        .get("https://www.claybbt.com/queryTitleByLanguageAndTitleId", {
          params: {
            language: lang,
            titleType: 2,
            titleId: this.titleId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.fourStageChildTitle = res.data.data;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {
    // bannerImg
    bannerImg() {
      const titleId = this.titleId;
      const current = this.fourStageTitle.find(
        (item) => item.titleId == titleId
      );
      return current ? current.img : current;
    },
  },
};
</script>

<style lang="less" scoped>
.banner-body {
  .banner-content {
    position: relative;
    // 图片展示
    .header {
      width: 100%;
      height: 100vh;
      position: relative;
      .header-text {
        height: 100px;
        width: 500px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        .ht-tit {
          font-size: 24px;
          color: #fff;
          text-align: center;
          font-weight: bold;
        }
        .ht-English {
          font-family: arial;
          font-size: 18px;
          display: block;
          text-align: center;
          color: #d80930;
          text-transform: uppercase;
          margin-top: 8px;
        }
        .ht-detail {
          margin-top: 13px;
          text-align: center;
          font-size: 15px;
          color: #d80930;
          .ht-line {
            padding: 0 10px;
            box-sizing: border-box;
          }
        }
      }
    }

    // 四个阶段
    .four-stage {
      height: 60px;
      width: 100%;
      // border-bottom: 1px solid #e5e5e5;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      // background-color: rgba(227, 6, 19, 0.6);
      position: absolute;
      left: 0;
      bottom: 0;
      .four-stage-box {
        height: 100%;
        display: flex;
      }
      .fs-title:last-child {
        margin-right: 0;
      }
      .fs-title {
        width: 250px;
        height: 100%;
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        cursor: pointer;
        margin-right: 20px;
        background-color: rgba(227, 6, 19, 0.6);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        .fs-title-chinese {
          color: #ffffff;
          font-size: 18px;
          line-height: 60px;
        }
        .fs-title-English {
          font-size: 12px;
          color: #afafaf;
          margin-top: 5px;
        }
      }
      .fs-title:first-child {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    // 背景
    .bg {
      transition: 0.3s;
      position: relative;
      .fs-title-chinese {
        color: rgb(255, 255, 255) !important;
      }
      .fs-title-English {
        color: #eca5ad !important;
      }
    }
    .bg:before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: -7px;
      left: 50%;
      margin-left: -7px;
      background: #d80930;
      border: 3px solid #fff;
      border-radius: 50%;
      transition: 0.6s;
    }
  }
}
</style>
