<template>
  <div class="public-header">
    <!-- 里边 -->
    <div class="nav-inside nav-inside-Mobile-none" v-if="type != 'right'">
      <div class="nav-header" :class="{ activeHeader: isShow }">
        <img class="logo" src="../../assets/images/公司logo.png" alt="" />
        <!-- <img class="logo" src="../../assets/images/lianxiwomen.png" alt="" /> -->

        <img
          class="more"
          @click="openHeader"
          src="../../assets/images/展开更多.png"
          alt=""
        />
      </div>
      <div class="nav-right" :class="{ activeRight: isShow }">
        <div class="nav-box">
          <img
            class="close"
            @click="closeHeader"
            src="../../assets/images/错号.png"
            alt=""
          />
          <div>
            <router-link
              @click.native="closeHeader"
              :to="{
                path: item.route,
                query: { titleId: item.titleId },
              }"
              v-for="(item, index) in navTitle"
              :key="index"
              class="nav-name"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 首页 -->
    <div class="nav-home" v-else>
      <div>
        <router-link
          :to="{
            path: item.route,
            query: { titleId: item.titleId },
          }"
          v-for="(item, index) in navTitle"
          :key="index"
          class="nav-name"
          >{{ item.name }}</router-link
        >
      </div>
    </div>

    <!-- 移动端显示的 -->
    <div class="nav-inside nav-inside-Mobile">
      <div class="nav-header" :class="{ activeHeader: isShow }">
        <img class="logo" src="../../assets/images/公司logo.png" alt="" />
        <img
          class="more"
          @click="openHeader"
          src="../../assets/images/展开更多.png"
          alt=""
        />
      </div>
      <div class="nav-right" :class="{ activeRight: isShow }">
        <div class="nav-box">
          <img
            class="close"
            @click="closeHeader"
            src="../../assets/images/错号.png"
            alt=""
          />
          <div>
            <router-link
              @click.native="closeHeader"
              :to="{
                path: item.route,
                query: { titleId: item.titleId },
              }"
              v-for="(item, index) in navTitle"
              :key="index"
              class="nav-name"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  props: {
    type: String,
  },
  name: "public-header",
  data() {
    return {
      navTitle: [],
      isShow: false,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryOneTypebyCHorEN();
  },
  methods: {
    queryOneTypebyCHorEN() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        // .get("https://www.claybbt.com/api/business/nav/list", {
        .get("https://www.claybbt.com/queryTitleByLanguage", {
          params: {
            language: lang,
            titleType: 1,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.navTitle = res.data.data;
            res.data.data.forEach((title) => {
              if (title.route == "/contact") {
                localStorage.setItem("contact", title.titleId);
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    openHeader() {
      this.isShow = true;
    },
    closeHeader() {
      this.isShow = false;
    },
    setLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    mouseOver() {
      this.isShowlanguage = true;
    },
    mouseLeave() {
      this.isShowlanguage = false;
    },
  },
  computed: {
    language() {
      const lang = localStorage.getItem("lang");
      if (lang === "CN") {
        return "中文";
      }
      return "English";
    },
  },
  filters: {},
};
</script>

<style lang="less" scoped>
// 导航首页样式
.nav-inside-Mobile {
  display: none;
  position: relative;
  .language-wrap {
    position: absolute;
    right: 11%;
    top: 20px;
    z-index: 99;
    min-height: 40px;
    width: 130px;
  }
  .change-language {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 40px;
      height: 40px;
    }
    .cl-text {
      width: 100px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgb(255, 255, 255);
      padding: 0 10px;
      box-sizing: border-box;
      font-weight: 600;
      cursor: pointer;
      text-shadow: 0px 1px 1px #474747;
      font-size: 18px;
    }
  }
  .select-language {
    width: 140px;
    margin-top: 50px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    .sl-box {
      display: flex;
      color: rgb(114, 114, 114);
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }
    .sl-box:hover {
      background-color: #d80930;
      transition: 0.3s;
      color: rgb(255, 255, 255);
    }
  }
}
.nav-home,
.nav-right {
  width: 140px;
  height: 100%;
  background-color: rgba(227, 6, 19, 0.6);
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  // padding: 0 10px;
  // box-sizing: border-box;
  a {
    color: #fff;
    font-size: 18px;
    margin: 30px auto;
    display: block;
    position: relative;
    padding: 10px 0 30px;
    text-align: center;
    border-bottom: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}
.nav-home {
  right: 20%;
  top: 0;
}
.nav-inside {
  .nav-header {
    width: 100%;
    padding: 10px 99px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(227, 6, 19, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    .logo {
      width: 100px;
    }
    .more {
      cursor: pointer;
      width: 60px;
      height: 30px;
      // opacity: 0.8;
    }
  }
  // 头部隐藏与显示
  .activeHeader {
    top: -200px !important;
    transition: 0.5s;
  }
  // .activeRight {
  //   top: 0px !important;
  //   transition: 0.5s;
  // }
  // 右边导航栏动画
  .nav-right {
    right: -200px;
    top: 0;
    transition: 0.5s;
    .nav-box {
      position: relative;
      .close {
        cursor: pointer;
        position: absolute;
        //修改
        // right: 0px;
        right: 25px;
        top: -50px;
        width: 35px;
        // width: 45px;
      }
    }
  }
  .activeRight {
    right: 0 !important;
    transition: 0.5s;
  }
}

.bg {
  position: relative;
  transition: 0.3s;
  color: #d80930 !important;
}
.bg::before {
  position: absolute;
  left: 0;
  bottom: -152%;
  content: " ";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #d80930;
  transition: 0.3s;
}
</style>
